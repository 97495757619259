
.qr-img img{
  width: 120px;
  height: 120px;
}
.text{
  text-align: right;
}
.url{
  display: block;
  height: 20px;
  /* color: rgb(29, 70, 158); */
  text-align: center;
  /* margin-bottom: 10px; */
}
.qr-img{
  background-color: white;
  padding-bottom: 20px;
  text-align: center;
}
.text img{
  width: 120px;
  height: 40px;
  margin-bottom: 20px;
}